<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Cashless from '@/services/Cashless';
  import modalViewTransaction from "@/components/modals/financials/modalViewTransaction";
  import DatePicker from "vue2-datepicker";
  import moment from "moment";


  export default {
  components: { Layout, PageHeader, DatePicker, modalViewTransaction },
  page: {
      title: "Topup",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  data() {
    return {
        error: null,
        filterData: false,
        tableData: [],
        title: "Topup",
        items: [
          {
            text: "Cashless",
            href: "/cashless",
          },
          {
            text: "Topup",
            active: true,
          },
        ],
        filterInput: {
          start_date: '',
          end_date: '',
        },
        isBusy: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "timestamp",
        sortDesc: false,
        transactionId: '',
        fields: [
            {
              key: "source",
              label: "Source",
            },
            {
              key: "transaction_id",
              label: "Transaction ID",
            },
            {
              key: "customer_id",
              label: "Customer",
              sortable: true,
            },
            {
              key: "serial",
              label: "Card Serial",
              sortable: true,
            },
            {
              key: "amount",
              label: "Amount",
              sortable: true,
            },
            {
              key: "amount_refunded",
              label: "Amount Refunded",
              sortable: true,
            },
            {
              key: "originator",
              label: "Originator",
              sortable: true,
            },
            {
              key: "created",
              label: "Date",
              sortable: true,
            },
            {
              key: "provider_completed",
              label: "Status",
              sortable: true,
            },
            "action"
        ],
        
    };
  },
      created() {
          const today = new Date();
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(today.getDate() - 7);


          this.filterInput.start_date = sevenDaysAgo.toISOString().split("T")[0];
          this.filterInput.end_date = today.toISOString().split("T")[0];

          this.getTopups();
      },
  computed: {

    totalTransactionSum: {
      get: function () {
        var transactionSum = this.tableData.reduce((acc, item) => acc + parseFloat(item.amount), 0);
        return transactionSum.toFixed(2);
      }
    },

    totalRefundedSum: {
      get: function () {
        return (this.tableData.reduce((acc, item) => (item.amount_refunded != null) ? acc + parseFloat(item.amount_refunded) : acc + 0.00, 0)).toFixed(2);
      }
    },

    averageValue: {
      get: function () {
        if(this.totalTransactionSum && this.tableData.length){
          return (this.totalTransactionSum / this.tableData.length).toFixed(2);
        }else{
          return '0.00';
        }
      }
    },

  },
  methods: {

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      async getTopups(){
          try {
            this.filterData = true;
            this.toggleBusy();
            var filters = this.getFilters()
            const response = await Cashless.getTopups(filters)
            this.tableData = response.data.data
            this.totalRows = this.tableData.length
            this.toggleBusy();
          } catch (error) {
            this.toggleBusy();
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
            this.totalRows = 0
          }
        },

        getFilters(){
          var filter_string = '';
          if(this.filterInput.start_date) {
            filter_string += (this.filterInput.start_date) ? '&start_date=' + moment(this.filterInput.start_date).format('YYYY-MM-DD') : "";
          }
          if(this.filterInput.end_date) {
            filter_string += (this.filterInput.end_date) ? '&end_date=' + moment(this.filterInput.end_date).format('YYYY-MM-DD') : "";
          }

          return filter_string = filter_string.substring(1);
        },

        modalViewTransaction(transactionId){
          this.$bvModal.show("view_transaction");
          this.transactionId = transactionId;
        },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="row mt-4">
              <div class="col-md-12">
                <div class="custom-accordion">
                  <a
                          class="text-body fw-semibold pb-2 d-block"
                          data-toggle="collapse"
                          href="javascript: void(0);"
                          role="button"
                          aria-expanded="false"
                          v-b-toggle.categories-collapse
                  >
                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                  </a>
                  <b-collapse visible id="categories-collapse">
                    <div class="card p-2 border shadow-none">
                      <div class="row">
                        <div class="col-sm-12 col-md-3">
                          <div class="form-group mb-3">
                            <b-form-group label="Start Date" label-for="formrow-start_date-input" class="mb-3">
                              <date-picker
                                      v-model="filterInput.start_date"
                                      append-to-body
                                      lang="en"
                                      confirm
                                      format="DD-MM-YYYY"
                              ></date-picker>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                          <div class="form-group mb-3">
                            <b-form-group label="End Date" label-for="formrow-end_date-input" class="mb-3">
                              <date-picker
                                      v-model="filterInput.end_date"
                                      append-to-body
                                      lang="en"
                                      confirm
                                      format="DD-MM-YYYY"
                              ></date-picker>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="col">
                        </div>
                        <div class="col">
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col d-inline-flex">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="getTopups">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                        </div>
                      </div>

                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="card" v-show="filterData">
          <div class="card-body">

            <div class="row mt-4 d-flex justify-content-around">

              <div class="col-md-3 col-xl-2">
                <div class="card bg-gradient-light">
                  <div class="card-body" >
                    <div>
                      <h5 class="text-muted mb-0 text-center ">Total Topups</h5>
                    </div>
                    <h5 v-if="rows > 0" class="mt-3 text-center">{{totalRows}}</h5>
                    <h5 v-else class="mt-3 text-center">{{totalRows}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-xl-2">
                <div class="card bg-gradient-light">
                  <div class="card-body" >
                    <div>
                      <h5 class="text-muted mb-0 text-center ">Total Sum</h5>
                    </div>
                    <h5 v-if="totalTransactionSum > 0" class="mt-3 text-center">{{totalTransactionSum}}</h5>
                    <h5 v-else class="mt-3 text-center">{{totalTransactionSum}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-xl-2">
                <div class="card bg-gradient-light">
                  <div class="card-body" >
                    <div>
                      <h5 class="text-muted mb-0 text-center">Average Value</h5>
                    </div>
                    <h5 v-if="averageValue > 0" class="mt-3 text-center">{{averageValue}}</h5>
                    <h5 v-else class="mt-3 text-center">{{averageValue}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-xl-2">
                <div class="card bg-gradient-light">
                  <div class="card-body" >
                    <div>
                      <h5 class="text-muted mb-0 text-center">Refunded Amount</h5>
                    </div>
                    <h5 v-if="totalRefundedSum > 0" class="mt-3 text-center">{{totalRefundedSum}}</h5>
                    <h5 v-else class="mt-3 text-center">{{totalRefundedSum}}</h5>
                  </div>
                </div>
              </div>

            </div>

            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-show="filterData">
              <b-table
                      :items="tableData"
                      :busy="isBusy"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      empty-text="No Data Found"
              >

                <template v-slot:cell(customer_id)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.customer_id}`}">
                        {{data.item.name}}
                      </router-link>
                    </li>
                  </ul>
                </template>

                <template v-slot:cell(serial)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link title="View Card Data" :to="{ path: `/cashless/card-details/${data.item.serial}`}">
                        {{data.item.serial}}
                      </router-link>
                    </li>
                  </ul>
                </template>

                <template v-slot:cell(amount)="data">
                    <span v-if="data.item.amount">{{parseFloat(data.item.amount).toFixed(2)}}</span>
                </template>

                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                              href="javascript:void(0);"
                              @click="modalViewTransaction(data.item.transaction_id)"
                              class="px-2 text-primary"
                              v-b-tooltip.hover
                              title="View Transaction Details"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>



                <template v-slot:cell(provider_completed)="data">
                  <div class="font-size-16" v-html="format_status(data.item.provider_completed == 1 ? 'completed' : 'failed')"></div>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- MODALS -->
    <modalViewTransaction :transactionId="transactionId"></modalViewTransaction>
    <!-- /MODALS -->

  </Layout>
</template>
